import React, { useState } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import blueArrow from '../images/blue-arrow.svg';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Title from '../components/layout/title';
import shortcodes from '../components/mdxCommon';
import Follow from '../components/follow';
import TitleSpacer from '../components/titleSpacer';
import texturedBgBlue from '../images/misc/textured-bg-blue.svg';

const LoveList = ({ className, items }) => {
  return (
    <ul className={className}>
      {items.map((it) => (
        <li
          key={it.text}
          className="flex w-full sm:w-[31%] lg:w-full items-center gap-4 font-light text-xs text-brand-gray-4"
        >
          <GatsbyImage
            className="flex-none object-cover w-20 h-16"
            image={getImage(it.image.childImageSharp)}
            alt={it.alt || ''}
          />
          <p className="flex-auto">{it.text}</p>
        </li>
      ))}
    </ul>
  );
};

const TeamMember = ({ image, name, position, twitter, loves, body }) => {
  const [expanded, setExpanded] = useState(false);

  const bioBox =
    'overflow-hidden h-32 md:h-auto prose prose-blue whitespace-pre-line mt-4 text-brand-gray-4';
  const expandedBioBox =
    'overflow-hidden h-auto prose prose-blue whitespace-pre-line mt-4 text-brand-gray-4';
  const upArrow = 'rotate-[135deg] w-2 h-2 -mt-1 ui-open:mt-1';
  const downArrow = 'rotate-[315deg] w-2 h-2 -mt-1 ui-open:mt-1';

  return (
    <div className="w-full bg-white p-5 lg:p-12 flex gap-4 lg:gap-12">
      <div className="w-1/3 lg:w-1/4 hidden md:block order-last sm:order-first">
        <div className="hidden sm:block">
          <GatsbyImage
            className="object-cover shadow-lg small-upper-left-clip aspect-1"
            image={image}
            alt={name}
          />
        </div>
        {loves && (
          <div className="pt-8 pb-8">
            <h5 className="text-center font-bold uppercase text-xs text-brand-gray-4 py-4 border-b border-slate-300 tracking-widest">
              What I love about SF
            </h5>
            <LoveList className="mt-8 space-y-6" items={loves} />
          </div>
        )}
      </div>
      <div className="w-full">
        <div className="font-bold border-b border-slate-300 pb-4 flex gap-4">
          <div className="w-1/4 md:hidden">
            <GatsbyImage
              className="object-cover shadow-lg small-upper-left-clip aspect-1 w-full"
              image={image}
              alt={name}
            />
          </div>
          <div>
            <h4 className="text-base lg:text-2xl text-brand-blue-5">{name}</h4>
            <p className="uppercase text-xs text-brand-gray-4 tracking-wider mt-1">{position}</p>
            <div className="mt-4">
              <Follow options={{ size: 'small' }} username={twitter} />
            </div>
          </div>
        </div>

        <div className={expanded ? expandedBioBox : bioBox}>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>{' '}
          {loves && (
            <div className="md:hidden">
              <h5 className="text-center font-bold uppercase text-xs text-brand-gray-4 py-4 border-b border-slate-300 tracking-widest">
                What I love about SF
              </h5>
              <LoveList className="mt-2 gap-3 flex flex-row flex-wrap h-auto" items={loves} />
            </div>
          )}
        </div>
        <button
          className="py-2 md:hidden flex justify-center items-center gap-1 w-full uppercase text-xs text-brand-blue-4 tracking-widest font-bold"
          onClick={() => setExpanded(!expanded)}
        >
          Read {expanded ? 'Less' : 'More'}
          <img src={blueArrow} alt="" className={expanded ? downArrow : upArrow} />
        </button>
      </div>
    </div>
  );
};

const AdvisoryBoardMember = ({ image, name, position, twitter }) => (
  <div key={name} className="w-full flex flex-row gap-4 sm:flex-col">
    <div className="flex-none w-32 h-32 sm:w-full sm:h-auto aspect-1">
      <GatsbyImage
        className="object-cover shadow-lg small-upper-left-clip w-full h-full"
        image={image}
        alt={name}
      />
    </div>
    <div className="font-bold">
      <h4 className="text-base lg:text-xl text-brand-gray-5">{name}</h4>
      <p className="uppercase text-xs text-brand-gray-4 tracking-wider mt-1">{position}</p>
      {twitter && (
        <div className="mt-4">
          <Follow options={{ size: 'small' }} username={twitter} />
        </div>
      )}
    </div>
  </div>
);

const TeamPage = ({ location, data }) => {
  // Banner Images
  const DannyCoyles = getImage(data.DannyCoyles.childImageSharp);
  const RecallBOE = getImage(data.RecallBOE.childImageSharp);
  const Tupelo = getImage(data.Tupelo.childImageSharp);
  const IHeartSF = getImage(data.IHeartSF.childImageSharp);
  const Astranis = getImage(data.Astranis.childImageSharp);

  return (
    <Layout>
      <SEO
        title="The GrowSF Team"
        description="GrowSF is a group of people who care about the future of San Francisco and want to see the city invest in growth so we can build for future generations."
        pathname={location.pathname}
      />
      <div className="max-w-7xl mx-auto px-5 sm:px-6 lg:px-8 pb-8">
        <Title title="The GrowSF Team">
          GrowSF is a group of people who care about the future of San Francisco and want to see the
          city invest in growth so we can build for future generations.
        </Title>
        <div className="flex flex-col-reverse gap-8 xl:flex-row lg:gap-12 items-center py-2 xl:pt-14 xl:pb-7 2xl:pt-2 2xl:pb-10">
          <div className="w-full xl:max-w-[473px] space-y-6 font-light">
            <p className="text-sm lg:text-xl text-gray-700 leading-relaxed">
              We all want a bright future for San Francisco, but local politicians are divided on
              how we will achieve this. Our local officials in charge today are not focused on
              actual progress, and residents feel under-informed about local issues and elections.
              We want to change that.
            </p>
            <p className="text-sm lg:text-xl text-gray-700 leading-relaxed">
              We want our city to be inclusive, livable, sustainable, and affordable for all
              families. We want clean streets, healthy transit systems, great public schools, and
              more housing. We love San Francisco and are working hard to make it the best city it
              can be.
            </p>
          </div>
          <div className="flex items-center gap-2 lg:gap-5 -mx-5 xl:mx-0 xl:absolute xl:right-0 xl:translate-y-6 2xl:relative 2xl:translate-y-0">
            <div>
              <GatsbyImage
                className="object-cover w-full h-full max-w-[218px] max-h-[290px]"
                image={DannyCoyles}
                alt="Danny Coyles helps GrowSF save outdoor dining"
              />
            </div>
            <div className="flex flex-col gap-2 lg:gap-5">
              <div className="w-fit flex items-end gap-2 lg:gap-5">
                <div className="max-w-[290px] max-h-[218px]">
                  <GatsbyImage
                    className="object-contain w-full h-full"
                    image={RecallBOE}
                    alt="GrowSF helped recall the School Board"
                  />
                </div>
                <div className="max-w-[218px] max-h-[290px]">
                  <GatsbyImage
                    className="object-contain w-full h-full"
                    image={Tupelo}
                    alt="Tupelo shows the GrowSF Save Outdoor Dining sign"
                  />
                </div>
              </div>
              <div className="w-fit flex items-start gap-2 lg:gap-5">
                <div className="max-w-[218px] max-h-[290px]">
                  <GatsbyImage
                    className="object-cover w-full h-full"
                    image={IHeartSF}
                    alt="I <3 SF by GrowSF in the window of a Painted Lady"
                  />
                </div>
                <div className="max-w-[290px] max-h-[218px]">
                  <GatsbyImage
                    className="object-cover w-full h-full"
                    image={Astranis}
                    alt="The GrowSF team at Astranis Space"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${texturedBgBlue})`,
          backgroundSize: 'contain',
        }}
      >
        <div className="mx-auto py-8 px-5 max-w-7xl sm:px-6 lg:px-8 lg:py-20">
          <div className="flex flex-col items-center gap-2 lg:gap-3">
            <h2 className="text-xs text-center font-bold tracking-wider uppercase">
              Get to Know Us
            </h2>
            <TitleSpacer />
            <h3 className="text-center font-bold text-xl lg:font-black lg:text-5xl">
              The GrowSF Team
            </h3>
          </div>
          <div className="mt-4 lg:mt-14 space-y-4 lg:space-y-12">
            {data.teamMembers.nodes.map((node, index) => (
              <TeamMember
                key={node.id}
                image={
                  node.frontmatter.image ? getImage(node.frontmatter.image.childImageSharp) : ''
                }
                name={node.frontmatter.name}
                position={node.frontmatter.position}
                twitter={node.frontmatter.twitter}
                loves={node.frontmatter.loves}
                body={node.body}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mx-auto py-8 px-5 max-w-7xl sm:px-6 lg:px-8 lg:py-20">
        <div className="flex flex-col items-center gap-2 lg:gap-3">
          <h2 className="text-xs text-center font-bold tracking-wider uppercase">Get to Know Us</h2>
          <TitleSpacer />
          <h3 className="text-center font-bold text-xl lg:font-black lg:text-5xl">
            The Advisory Board
          </h3>
        </div>
        <div className="mt-8 lg:mt-14 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-flow-row gap-8 lg:gap-12">
          {data.advisoryBoard.nodes.map((node, index) => (
            <AdvisoryBoardMember
              key={node.id}
              image={node.frontmatter.image ? getImage(node.frontmatter.image.childImageSharp) : ''}
              name={node.frontmatter.name}
              position={node.frontmatter.position}
              twitter={node.frontmatter.twitter}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default TeamPage;

export const query = graphql`
  query {
    # Banner Images
    DannyCoyles: file(relativePath: { eq: "team/danny-coyles-save-outdoor-dining.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    RecallBOE: file(relativePath: { eq: "team/growsf-recall-the-school-board.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    Tupelo: file(relativePath: { eq: "team/tupelo-save-outdoor-dining.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    IHeartSF: file(relativePath: { eq: "team/i-heart-sf-growsf.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    Astranis: file(relativePath: { eq: "team/growsf-astranis.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    teamMembers: allMdx(
      sort: { fields: frontmatter___priority, order: ASC }
      filter: { frontmatter: { post_type: { eq: "team" }, team_type: { regex: "/staff|board/" } } }
    ) {
      nodes {
        frontmatter {
          image: image {
            childImageSharp {
              gatsbyImageData
            }
          }
          name
          position
          twitter
          priority
          loves {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
            text
          }
        }
        body
      }
    }
    advisoryBoard: allMdx(
      sort: { fields: frontmatter___priority, order: ASC }
      filter: { frontmatter: { post_type: { eq: "team" }, team_type: { eq: "advisory" } } }
    ) {
      nodes {
        frontmatter {
          image: image {
            childImageSharp {
              gatsbyImageData
            }
          }
          name
          position
          twitter
          priority
        }
        body
      }
    }
  }
`;
